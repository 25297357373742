import React from 'react';
import {ICartItem} from '../../../../../../../../types/app.types';
import {CartItemDataHook} from '../../CartItem';
import s from '../../CartItem.scss';
import {useControllerProps} from '../../../../../ControllerContext';

export const TotalPrice = ({item}: {item: ICartItem}) => {
  const {addGlobalEAttributesForCart} = useControllerProps().cartStore;
  return addGlobalEAttributesForCart ? (
    <div
      className={s.totalPrice}
      data-hook={CartItemDataHook.TotalPrice}
      data-wix-item-total-price={CartItemDataHook.TotalPrice}>
      {item.convertedPrices.formattedTotalPrice}
    </div>
  ) : (
    <div className={s.totalPrice} data-hook={CartItemDataHook.TotalPrice}>
      {item.convertedPrices.formattedTotalPrice}
    </div>
  );
};
