import React from 'react';
import s from '../../CartItem.scss';
import {CartItemDataHook} from '../../CartItem';
import {ICartItem} from '../../../../../../../../types/app.types';
import {useControllerProps} from '../../../../../ControllerContext';

export const Price = ({item}: {item: ICartItem}) => {
  const {addGlobalEAttributesForCart} = useControllerProps().cartStore;
  const {formattedComparePrice, formattedPrice, formattedPriceBeforeDiscount} = item.convertedPrices;

  const crossedOutPrice = formattedPriceBeforeDiscount || formattedComparePrice;
  return (
    <div className={s.prices}>
      {crossedOutPrice &&
        (addGlobalEAttributesForCart ? (
          <span
            data-hook={CartItemDataHook.ComparePrice}
            data-wix-line-full-item-price={CartItemDataHook.ComparePrice}
            className={s.comparePrice}>
            {crossedOutPrice}
          </span>
        ) : (
          <span data-hook={CartItemDataHook.ComparePrice} className={s.comparePrice}>
            {crossedOutPrice}
          </span>
        ))}
      {formattedPrice &&
        (addGlobalEAttributesForCart ? (
          <span
            data-hook={CartItemDataHook.Price}
            className={s.price}
            data-wix-line-item-price={CartItemDataHook.Price}>
            {formattedPrice}
          </span>
        ) : (
          <span data-hook={CartItemDataHook.Price} className={s.price}>
            {formattedPrice}
          </span>
        ))}
    </div>
  );
};
